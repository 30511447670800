.Alert {
    position: fixed; /* фиксированное положение */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(240, 243, 245, 0.86); /* цвет фона */
    z-index: 999999;
    -webkit-transition: opacity 200ms ease-in;
    -moz-transition: opacity 200ms ease-in;
    transition: opacity 200ms ease-in; /* анимация перехода */
    margin: 0;
    padding: 0;
    opacity: 1; /* делаем окно видимым */
    pointer-events: auto; /* элемент видим для событий мыши */
    overflow-y: scroll; /* добавляем прокрутку по y, когда элемент не помещается на страницу */

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

/* ширина модального окна и его отступы от экрана */
.Alert__dialog {
    position: relative;
    //width: auto;
    margin: 96px 16px;

    /* свойства для блока, содержащего контент модального окна */
    .Alert__content {
        position: relative;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        background-color: white;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        border-radius: 12px;
        outline: 0;
        padding: 42px;
        //width: 460px;
        min-height: 12px;

        /* свойства для блока, содержащего основное содержимое окна */
        .Alert__body {
            position: relative;
            -webkit-box-flex: 1;
            -webkit-flex: 1 1 auto;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            overflow: hidden;
        }

        /* свойства для заголовка модального окна */
        .Alert__close {
            position: absolute;
            top: 42px;
            right: 42px;
            height: 21px;
            width: 21px;

            .Button__close {
                height: 21px;
                width: 21px;
                color: #000;
                opacity: .25;
                cursor: pointer;
                transition: ease .2s;

                &.icon-close {
                    height: 21px;
                    width: 21px;
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMS4yMTMiIGhlaWdodD0iMjEuMjEzIiB2aWV3Qm94PSIwIDAgMjEuMjEzIDIxLjIxMyI+ICA8ZyBpZD0iR3JvdXBfOTA5IiBkYXRhLW5hbWU9Ikdyb3VwIDkwOSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTU1Mi4yNSAtMTAzOC43NCkgcm90YXRlKDQ1KSI+ICAgIDxyZWN0IGlkPSJSZWN0YW5nbGVfMTE4MCIgZGF0YS1uYW1lPSJSZWN0YW5nbGUgMTE4MCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjYiIHJ4PSIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMTI4IDM0MSkiIGZpbGw9IiMyQTNFNEMiLz4gICAgPHJlY3QgaWQ9IlJlY3RhbmdsZV8xMTgxIiBkYXRhLW5hbWU9IlJlY3RhbmdsZSAxMTgxIiB3aWR0aD0iNiIgaGVpZ2h0PSIyNCIgcng9IjIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDExMzcgMzMyKSIgZmlsbD0iIzJBM0U0QyIvPiAgPC9nPjwvc3ZnPg==);
                    background-repeat: no-repeat;
                }

                /* свойства для кнопки "Закрыть" при нахождении её в фокусе или наведении */
                &:focus, &:hover {
                    opacity: .5;
                }
            }
        }
    }
}

//@media (min-width: 576px) {
//    .Modal__dialog {
//        max-width: 576px;
//        margin: 192px auto; /* для отображения модального окна по центру */
//    }
//}
