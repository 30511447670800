.module__wrapper {
  width: calc(100% - 96px);
  padding: 72px 48px;
}

.module__title {
  font: 300 42px Roboto;
  margin-bottom: 48px;
}

.module__header {
  margin-bottom: 48px;
}

.module__header .header__title {
  display: inline-block;
  font: 700 42px Roboto;
  margin-right: 48px;
}

.module__header .header__link {
  color: #0f1922;
  display: inline-block;
  font: 400 19px Roboto;
  opacity: 0.25;
}

.module__header .header__button {
  float: right;
  margin-top: 5px;
}

.profile__name {
  font: 300 29px Roboto;
  margin-bottom: 12px;
  opacity: 0.87;
}

.profile__role {
  font: 400 14px Roboto;
  opacity: 0.47;
  margin-bottom: 32px;
}

.module__hr {
  background-color: #E8ECEF;
  height: 2px;
  width: 100%;
  margin-bottom: 48px;
}

.module__information { margin-bottom: 48px; }

.information__title {
  margin-bottom: 24px;
  font: 400 21px Roboto;
  opacity: 0.87;
}

.information__content { margin-bottom: 24px; }

.information__label {
  font: 400 14px Roboto;
  opacity: 0.47;
  margin-bottom: 12px;
}

.information__text {
  font: 400 16px Roboto;
  opacity: 0.87;
}

.text_red {
  font: 900 18px Roboto;
  color: #FF0B0B;
  opacity: 1;
}

.text_green {
  font: 900 18px Roboto;
  color: #1FA520;
  opacity: 1;
}

.text_blue {
  font: 900 18px Roboto;
  color: #006DDB;
  opacity: 1;
}

.text_black {
  font: 900 18px Roboto;
}

.information__button { margin-top: 32px; }

.button {
  color: #6C6F72;
  /*text-transform: uppercase;*/
  font: 900 16px Roboto;
  border-radius: 6px;
  border: none;
  padding: 12px 16px 11px;
  cursor: pointer;
  letter-spacing: .47px;
}

.button_blue {
  background-color: #0B7AFF;
  color: white;
}

.button_icon {
  border: none;
  height: 20px;
  width: 20px;
  background-color: white;
  background-size: 20px;
  background-repeat: no-repeat;
  opacity: .3;
  cursor: pointer;
}

.button_icon:hover {
  opacity: .7;
}

.module__field_inline {
  display: flow-root;
  width: calc(100% - 32px);
  background-color: #fff;
  border-radius: 8px;
  border: none;
  padding: 14px 16px;
  margin-bottom: 8px;
}

.field_inline__box_right {
  display: inline-block;
  float: right;
}

.field_inline__box {
  display: inline-block;
  margin-right: 32px;
}

.field_inline__label {
  font: 900 14px Roboto;
  display: inline-block;
  text-transform: uppercase;
  color: #BEBEBF;
  margin-right: 12px;
}

.field_inline__text {
  font: 400 16px Roboto;
  display: inline-block;
}

.field_inline__text.text_green {
  font-weight: 900;
}

.field_inline__text.text_red {
  font-weight: 900;
}

.field_inline__button {
  /*background-color: #F4F4F5;*/
  font: 900 16px Roboto;
  display: inline-block;
  color: #BEBEBF;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: .37s;
  letter-spacing: .47px;
}

.field_inline__button:hover {
  transition: .37s;
  color: #818182;
}

.field_inline__hr {
  width: 2px;
  height: 15px;
  display: inline-block;
  position: relative;
  margin-left: -16px;
  margin-right: 16px;
  padding: 0;
}

.field_inline__hr .hr__line {
  position: absolute;
  top:0;
  left: 0;
  border-radius: 2px;
  width: 3px;
  height: 19px;
  background-color: #D6D6D7;
}

.field_inline__mark {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #BEBEBF;
  border-radius: 50%;
  margin-right: 12px;
  vertical-align: top;
  margin-top: 5px;
}

.mark_green { background-color: #8BC34A; }

.mark_red { background-color: #FF0B0B; }

.module__field_header {
  margin-bottom: 32px;
  display: flow-root;
}

.module__field_header .field_header__title {
  display: inline-block;
  font: 700 36px Roboto;
  opacity: 0.87;
  margin-right: 48px;
}

.module__field_header .field_header__button {
  float: right;
  /*background-color: #f4f4f5;*/
  /*color: #bebebf;*/
  background-color: #dedede;
  color: #a9a9a9;
}

.field_header__button:hover {
  transition: .37s;
  color: #818182;
}




















.table__device {
  width: calc(100%);
  border-radius: 0;
  background-color: #f4f4f5;
  border: none;
}

.tr__device_header {
  background-color: #F4F4F5;
  width: calc(100%);
  margin-bottom: 24px;
}

.th__device {
  font: 900 14px Roboto;
  text-transform: uppercase;
  color: #BEBEBF;
  padding: 16px 16px 14px;
  background-color: white;
}

.tr__device {
  padding: 14px 16px;
}

.td__device {
  text-align: center;
  padding: 14px 16px;
  font: 400 16px Roboto;
  background-color: white;
}

.device__link.link_blue {
  color: #006DDB;
  cursor: pointer;
  font: 400 16px Roboto;
  border: none;
  background-color: white;
  font-weight: 900;
  padding: 0;
}

.td__device.text_green {
  font-weight: 900;
}

.td__device.text_red {
  font-weight: 900;
}








/******************************** MODULE-MESSAGES */
.system-notification {
  left: 0;
  padding-bottom: 12px;
  pointer-events: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2000;
}

.messages-block {
  display: block;
  /*float: right;*/
  margin: 0 auto;
  max-width: 100%;
  padding: 0 8px;
  /*margin-right: 48px;*/
  pointer-events: none;
  text-align: center;
}

.messages-short {
  /* margin-bottom: 8px; */
  /* padding: 13px 18px 12px; */
  /* border-radius: 37px; */
  /* text-transform: uppercase; */
  /* font-family: Helvetica, Arial, sans-serif; */
  /* font-weight: lighter; */
  /* line-height: 1.1; */
  /* font-size: 11px; */
  /* letter-spacing: 1.1px; */
  /* cursor: pointer; */
  pointer-events: auto;
  display: inline-block;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
  padding: 9px 16px 8px;
  border: 2px solid;
  -webkit-border-radius: 42px;
  -moz-border-radius: 42px;
  border-radius: 6px;
  cursor: pointer;
  letter-spacing: .3px;
  transition: .37s;
}

.messages-short:hover {
  box-shadow: 0 12px 20px -10px rgba(0,0,0,.37);
  transition: .37s;
}

.messages-short.red {
  color: #fff;
  /*background-color: #ff6737;*/
  /*border-color: #ff6737;*/
  background-color: #FF0B0B;
  border-color: #FF0B0B;
}

.messages-short.blue {
  color: #fff;
  /*background-color: #03a9f4;*/
  /*border-color: #03a9f4;*/
  background-color: #0B7AFF;
  border-color: #0B7AFF;
}

/*.messages-short.yellow {*/
  /*font-weight: 700;*/
  /*color: #000000;*/
  /*background-color: #ffe500;*/
  /*border-color: #ffe500;*/
/*}*/

.messages-short.green {
  color: white;
  /*background-color: #cddc39;*/
  /*border-color: #cddc39;*/
  background-color: #1FA520;
  border-color: #1FA520;
}

.messages-short.black {
  color: white;
  /*background-color: #cddc39;*/
  /*border-color: #cddc39;*/
  background-color: #000000;
  border-color: #000000;
}
