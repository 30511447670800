.App {
    width: 100%;
    height: 100%;

    .Cabinet {
        width: 100%;
        height: 100%;

        .Page {
            padding-left: 256px;
            width: calc(100% - 256px);
            height: 100%;
        }
    }
}
