.Loader, .Loader:after {
  border-radius: 50%;
  width: 12px;
  height: 12px;
}
.Loader {
  margin: 0 auto;
  position: relative;
  border-top: 3px solid rgba(32, 32, 32, 0.1);
  border-right: 3px solid rgba(32, 32, 32, 0.1);
  border-bottom: 3px solid rgba(32, 32, 32, 0.1);
  border-left: 3px solid #202020;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1s infinite linear;
  animation: load8 1s infinite linear;
}

.Loader_white {
  border-left: 3px solid white !important;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
