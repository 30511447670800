.module__modal {
  z-index: 1000;
  left: 0px;
  top: 0px;
  position: fixed;
  animation-name: modal_theme_normal_visible;
  visibility: visible;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  opacity: 1;
}

.modal__overlay {
  width: 100%;
  height: 100%;
  display: table;
  text-align: center;
  background: rgba(50,50,50,.47);
  padding: 0;
  overflow-y: hidden;
}

.modal__window {
  display: table-cell;
  vertical-align: middle;
  padding: 0;
}

.modal__window .modal__content {
  margin: 12px;
  background: #fff;
  border-radius: 12px;
  /*padding: 48px 48px 0;*/
  padding: 42px 42px 0;
  width: 280px;
  animation-name: modal_theme_normal_visible__content;
  animation-timing-function: ease-in-out;
  display: inline-block;
  text-align: left;
  overflow-y: auto;
}

.modal__content.content__widthtwo { width: 602px; }

.modal__content.content__widthone { width: 462px; }

.modal__title {
  font: 300 29px Roboto;
  margin-bottom: 12px;
  opacity: 0.87;
}

.modal__title.title_firm {
  font-size: 26px;
}

.modal__role {
  font: 400 14px Roboto;
  opacity: 0.47;
  margin-bottom: 32px;
}

.modal__form {
  margin-top: 42px;
  /*margin-bottom: 42px;*/
}

.form__title {
  font: 400 21px Roboto;
  margin-bottom: 36px;
  opacity: 0.87;
  margin-top: -10px;
}

.form__info {
  font: 400 16px Roboto;
  margin-bottom: 36px;
  opacity: 0.87;
  margin-top: -10px;
}

.form__field {
  display: inline-block;
  position: relative;
  margin-bottom: 32px;
}

.form__field.field__margin_right { margin-right: 38px; }

.form__field .field__title {
  position: absolute;
  left: 12px;
  top: -8px;
  font: 400 14px Roboto;
  color: rgba(29, 33, 39, 0.47);
  background-color: white;
  border-radius: 4px;
  display: inline-block;
  padding: 0 5px;
  transition: .37s;
  cursor: default;
}

.form__field .field__input {
  width: 244px;
  padding: 17px 16px 16px;
  font: 400 16px Roboto;
  background: #fff;
  border-radius: 6px;
  border: solid 2px #EFEFEF;
  resize: vertical;
  outline: none;
  cursor: text;
  transition: .37s;
}

.field__input._password {
  font: 900 26px Roboto;
  letter-spacing: 3px;
  padding: 12px 16px 8px;
  color: #3e3e3e;
}

.form__field .field__button {
  /*background-color: #0B7AFF;*/
  /*border-radius: 6px;*/
  /*color: white;*/
  /*display: inline-block;*/
  /*font: 900 13px Roboto;*/
  /*border: none;*/
  /*padding: 12px 16px 11px;*/
  /*outline: none;*/
  /*cursor: pointer;*/
  /*transition: 0.47s;*/
  /*text-transform: uppercase;*/

  height: 42px;
  background-color: #0B7AFF;
  border-radius: 6px;
  color: white;
  display: inline-block;
  font: 900 16px Roboto;
  border: none;
  padding: 0 16px;
  outline: none;
  cursor: pointer;
  transition: 0.47s;
}

.field__button:hover {
  opacity: 1;
  transition: 0.47s;
}

.field__button:focus {
  opacity: 1;
  transition: 0.47s;
}

.field__button.button_white_blue {
  background-color: white;
  color: #0B7AFF;
}

.field__button.button_white_gray {
  background-color: white;
  color: #666666;
}

.field__button.button__gray {
  background-color: #dddddd;
  color: #666666;
}

.field__button.button_red {
  background-color: #FFE1E1;
  color: #FF0B0B;
}

.field__button.button_left { margin-right: 20px; }

.field__button.button_small {
  padding: 6px 8px 5px;
  font: 900 12px Roboto;
}

.form__field .field__error {
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity .2s linear .1s;
  transition: opacity .2s linear .1s;
  position: absolute;
  left: 12px;
  min-width: 256px;
  bottom: -20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  transition: .37s;
}

.field__error .error__text {
  font: 400 12px Roboto;
  color: #ff693a;
  -ms-flex-item-align: center;
  align-self: center;
  display: inline-block;
  max-width: 256px;
}

._error .field__title {
  color: #ff5722;
  transition: .37s;
}

._error .field__input {
  border-color: #ffefe9;
  transition: .37s;
}

._error .field__error {
  opacity: 1;
  transition: .37s;
}

.form__footer { margin: 10px auto; }

.form__footer .link {
  color: #0B7AFF;
  text-decoration: none;
  text-transform: none;
  transition: 0.47s;
  font: 400 14px Roboto;
  letter-spacing: 0;
  padding: 11px 16px 10px;
}

.form__footer .link:hover {
  opacity: 1;
  transition: 0.47s;
}

.button_white_gray.link {
  color: #666666;
}

.modal__page {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.modal__content section {
  padding: 0;
  margin: 0;
}

.modal__page .modal__content {
  margin: 0;
  background: #fff;
  padding: 0;
  width: 376px;
  height: 100%;
  animation-name: modal_theme_normal_visible__content;
  animation-timing-function: ease-in-out;
  display: block;
  text-align: left;
  overflow-y: auto;
  overflow-x: hidden;
}

.modal__page .content {
  width: 292px;
  padding: 42px 42px 18px;
}

.modal__page .page__hr {
  width: 100%;
  height: 2px;
  background-color: #F9FAFB;
  margin-bottom: 24px;
}

.form__field .field_inline__text {
  opacity: .72;
}
