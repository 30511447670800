.Form {
    position: relative;
    display: block;
    padding: 0;
    margin: 0;
    width: 460px;

    &_null {
        width: 21px;
        height: 21px;
    }

    &_width-796 {
        width: 796px;
    }

    &_width-690 {
        width: 690px;
    }

    .Form__header {
        margin-bottom: 32px;

        .Header__title {
            font-family: Roboto, sans-serif;
            font-weight: 700;
            font-size: 32px;
            color: #2A3E4C;
        }
    }

    .Form__content {
        margin-bottom: 22px;

        .Content__box {
            position: relative;

            .Navigate__wrapper {
                text-align: center;
                margin: 0 0 24px;
                width: 448px;
                display: inline-block;
                padding: 6px 3px;
                border: 2px solid #f0f3f5;
                border-radius: 9px;

                &_top {
                    margin-top: 6px;
                }

                &_margin {
                    margin-right: 48px;
                }

                &_left {
                    float: left;
                    margin-left: 24px;
                }

                &_right {
                    float: right;
                    margin-right: 24px;
                }
            }

            .Navigate__link {
                display: inline-block;
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                font-size: 16px;
                margin: 0 3px 0 4px;
                transition: ease 0.3s;
                cursor: pointer;
                padding: 6px 29px;
                border-radius: 5px;
                color: #2A3E4C;
                opacity: .3;

                &.active {
                    //opacity: 0.9;
                    opacity: 1;
                    background-color: #f0f3f5;
                    cursor: default;

                    &:hover {
                        //opacity: 0.9;
                        opacity: 1;
                        background-color: #f0f3f5;
                    }
                }

                &:hover {
                    opacity: 0.5;
                }
            }

            .Label__question {
                position: absolute;
                top: 3px;
                right: 6px;
                display: inline-block;
                float: right;
                height: 18px;
                width: 17px;
                background-color: #2a3e4c;
                opacity: 0.25;
                transition: ease 0.3s;
                cursor: pointer;
                border-radius: 50%;
                padding-left: 1px;

                &.icon-question {
                    font-family: 'Roboto', sans-serif;
                    font-weight: 900;
                    font-size: 16px;
                    color: #fff;
                    text-align: center;
                }

                &:hover {
                    opacity: 1;
                }
            }

            .Box__label {
                //width: 450px;
                //width: 100%;
                width: calc(100% - 10px);
                margin-bottom: 6px;
                font: 400 14px Roboto;
                color: #7B898D;
                background-color: white;
                border-radius: 4px;
                display: inline-block;
                padding: 0 0 0 5px;
                transition: .37s;
                cursor: default;

                &_link {
                    float: right;
                    text-decoration: none;
                    color: #0066cc;
                    cursor: pointer;
                    opacity: 0.7;
                    transition: ease 0.3s;

                    &:hover {
                        opacity: 1;
                    }
                }
            }

            .Box__info {
                font: 400 14px Roboto;
                margin: 20px 0;
                color: #2a3e4c;
                text-align: center;
            }

            .Box__text {
                font-size: 18px;
                color: #2A3E4C;
                font-weight: 400;
                font-family: Roboto, sans-serif;
                margin-bottom: 20px;

                &_disabled {
                    opacity: .25;
                }

                &_center {
                    text-align: center;
                }
            }

            .Box__link {
                //width: 450px;
                //width: 100%;
                padding: 0 19px 0 5px;
                font-family: Roboto, sans-serif;
                font-weight: 400;
                font-size: 16px;
                text-decoration: none;
                color: #0066cc;
                cursor: pointer;
                opacity: 0.7;
                transition: ease 0.3s;
                display: inline-block;

                &:hover {
                    opacity: 1;
                }
            }

            .Box__input {
                //width: 424px;
                width: calc(100% - 36px);
                margin-bottom: 20px;
                padding: 17px 16px 16px;
                font: 400 16px Roboto;
                background: #fff;
                border-radius: 0;
                border: solid 2px #f0f3f5;
                resize: vertical;
                outline: none;
                cursor: text;
                transition: .37s;

                &_password {
                    font: 900 26px Roboto !important;
                    letter-spacing: 3px !important;
                    padding: 11px 16px 9px !important;
                    color: #3e3e3e !important;
                }

                &_margin-none {
                    margin: 0;
                }
            }

            .Box__candy {
                display: inline-block;
                margin: 0 16px 12px 0;
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                font-size: 16px;
                border-radius: 5px;
                padding: 7px 10px 6px;
                color: #2A3E4C;
                background-color: #f0f3f5;

                //.Label__text {
                //    display: inline-block;
                //    opacity: 0.7;
                //}

                .Label__clear {
                    margin-left: 10px;
                    display: inline-block;
                    float: right;
                    height: 18px;
                    width: 18px;
                    background-color: #cacfd3;
                    opacity: 1;
                    transition: ease 0.3s;
                    cursor: pointer;
                    border-radius: 50%;

                    &.icon-clear {
                        font-family: 'Roboto', sans-serif;
                        font-weight: 700;
                        font-size: 15px;
                        color: #f0f3f5;
                        text-align: center;
                        -moz-transform: rotate(45deg); /* Для Firefox */
                        -ms-transform: rotate(45deg); /* Для IE */
                        -webkit-transform: rotate(45deg); /* Для Safari, Chrome, iOS */
                        -o-transform: rotate(45deg); /* Для Opera */
                        transform: rotate(45deg);
                    }

                    &:hover {
                        background-color: #959fa6;
                    }
                }
            }

            .Box__file {
                //width: 424px;
                width: calc(100% - 36px);
                margin-bottom: 20px;
                padding: 17px 16px 16px;
                font: 400 16px Roboto;
                background: #fff;
                border-radius: 0;
                border: solid 2px #f0f3f5;
                resize: vertical;
                outline: none;
                cursor: pointer;
                transition: .37s;
            }

            .Box__select {
                position: relative;
                //width: 460px;
                width: 100%;
                margin-bottom: 20px;
                padding: 17px 12px 16px;
                font: 400 16px Roboto;
                background-color: #fff;
                border-radius: 0;
                border: solid 2px #f0f3f5;
                resize: vertical;
                outline: none;
                cursor: pointer;
                transition: .37s;

                background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNi45NzEiIGhlaWdodD0iMTIuMDIxIiB2aWV3Qm94PSIwIDAgMTYuOTcxIDEyLjAyMSI+ICA8ZyBpZD0iR3JvdXBfOTIwIiBkYXRhLW5hbWU9Ikdyb3VwIDkyMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEwNDAuODYxIDU3MC42MzUpIHJvdGF0ZSgtNDUpIj4gICAgPHJlY3QgaWQ9IlJlY3RhbmdsZV8xMTgwIiBkYXRhLW5hbWU9IlJlY3RhbmdsZSAxMTgwIiB3aWR0aD0iMTIiIGhlaWdodD0iNSIgcng9IjIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDExMzcgMzQyKSIgZmlsbD0iI2NhY2ZkMyIvPiAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlXzExODEiIGRhdGEtbmFtZT0iUmVjdGFuZ2xlIDExODEiIHdpZHRoPSI1IiBoZWlnaHQ9IjEyIiByeD0iMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTEzNyAzMzUpIiBmaWxsPSIjY2FjZmQzIi8+ICA8L2c+PC9zdmc+);
                background-repeat: no-repeat;
                background-position: right 16px center;
                fill: black;

                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none !important;

                .option__hr {
                    width: 100%;
                    font-size: 4px;
                }

                .option__info {
                    font: 400 12px Roboto;
                }
            }
        }

        .Content__hr {
            //width: 448px;
            width: calc(100% - 12px);
            margin: 12px auto 20px;
            height: 2px;
            background-color: #EFF2F5;
            border: none;
        }

        .Content__br {
            height: 24px;
        }

        .Content__error {
            width: 460px;
            opacity: 1;
            pointer-events: none;
            -webkit-transition: opacity .2s linear .1s;
            transition: opacity .2s linear .1s;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;

            .Error__text {
                font: 400 16px Roboto;
                color: #ff693a;
                align-self: center;
                display: inline-block;
                max-width: 460px;
                margin-left: 5px;
            }
        }
    }



    .Form__footer {
        height: 52px;

        .Form__button {
            height: 52px;
            width: 230px;
            vertical-align: top;
            background-color: #cacfd3;
            border-radius: 5px;
            color: #959fa6;
            display: inline-block;
            font-family: Roboto, sans-serif;
            font-weight: 700;
            font-size: 16px;
            border: none;
            padding: 0 16px;
            outline: none;
            cursor: pointer;
            transition: ease 0.2s;
            letter-spacing: 0.05em;
            opacity: 0.5;

            &_cancel {
                background-color: white;
                width: 188px;

                &:hover {
                    opacity: 0.6 !important;
                    color: #606f79;
                }
            }

            &_disabled {
                opacity: 1 !important;
            }

            &_left {
                margin-right: 42px;
            }

            &_blue {
                background-color: #0066CC;
                color: white;
                font-weight: 700;
                opacity: 0.9;
            }

            &_red {
                background-color: #F44336;
                color: white;
                font-weight: 700;
                opacity: 0.9;
            }

            &:hover {
                opacity: 1;
            }

            &:focus {
                opacity: 1;
            }
        }
    }
}

.Form__link {
    margin-top: 42px;

    .Link__delete {
        text-align: center;
        font-family: Roboto, sans-serif;
        font-weight: 400;
        font-size: 16px;
        text-decoration: none;
        color: #FF4C55;
        cursor: pointer;
        opacity: 0.7;
        transition: ease 0.3s;

        &:hover {
            opacity: 1;
        }
    }
}

.Form {

    .Programs__table {
        display: block;
        margin: 0 0 24px;
        padding: 0;
        //width: 868px;
        border-collapse: collapse;
        border-spacing: 0 0;

        .Table__tr {
            margin: 0;
            padding: 0;

            .Table__th {
                position: relative;
                margin: 0;
                padding: 16px 0 0;
                width: 864px;
                //height: 70px;
                color: #9DA7AE;
                background-color: white;
                border: 2px solid #E6EAED;
                cursor: default;

                .Th__title {
                    float: left;
                    display: inline-block;
                    padding: 0 24px 17px;
                    //margin-right: 24px;
                    width: 518px;
                    font-family: Roboto, sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    text-align: left;
                }

                .Th__period {
                    display: inline-block;
                    padding: 0 24px 17px;
                    //margin-right: 48px;
                    width: 148px;
                    font-family: Roboto, sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    text-align: right;
                }

                .Th__count {
                    float: right;
                    display: inline-block;
                    padding: 0 24px 17px;
                    width: 128px;
                    font-family: Roboto, sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    text-align: right;
                }
            }

            .Table__td {
                position: relative;
                margin: 0;
                //padding: 19px 0 14px;
                padding: 13px 0 12px;
                width: 864px;
                background-color: white;
                border: 2px solid #E6EAED;

                .Program__name {
                    width: 400px;
                    vertical-align: top;
                    display: inline-block;
                    margin-left: 24px;
                    font-family: Roboto, sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    padding-top: 5px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    word-wrap: break-word;
                }

                .Program__count {
                    vertical-align: top;
                    float: right;
                    text-align: right;
                    display: inline-block;
                    padding: 0 24px 0 0;
                    font-family: Roboto, sans-serif;
                    font-weight: 900;
                    font-size: 24px;
                    color: #2A3E4C;
                    text-transform: uppercase;
                }

                .Program__count_edit {
                    position: absolute;
                    top: 18px;
                    right: 249px;
                    font-family: 'Roboto', sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    color: #0066CC;
                    text-transform: none;
                    //opacity: 0;
                    opacity: 0.7;
                    cursor: pointer;
                    text-align: right;
                }

                .Program__edit {
                    position: absolute;
                    top: 18px;
                    right: 110px;
                    font-family: 'Roboto', sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    color: #0066CC;
                    text-transform: none;
                    //opacity: 0;
                    opacity: 0.7;
                    cursor: pointer;
                    text-align: right;
                }

                .Program__delete {
                    position: absolute;
                    top: 18px;
                    right: 24px;
                    font-family: 'Roboto', sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    color: #F44336;
                    text-transform: none;
                    //opacity: 0;
                    opacity: 0.7;
                    cursor: pointer;
                    text-align: right;
                }

                .Link {
                    color: #61717B;

                    &:hover {
                        color: #2A3E4C;
                    }
                }

                &:hover {
                    color: #2A3E4C;
                    background-color: white;

                    .Link {
                        color: #2A3E4C;
                    }

                    .Program__count {
                        opacity: 0;
                    }

                    .Program__count_edit {
                        opacity: 0.7;
                        transition: ease 0.3s;

                        &:hover {
                            opacity: 1;
                        }
                    }

                    .Program__edit {
                        opacity: 0.7;
                        transition: ease 0.3s;

                        &:hover {
                            opacity: 1;
                        }
                    }

                    .Program__delete {
                        opacity: 0.7;
                        transition: ease 0.3s;

                        &:hover {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    .Counts__table {
    display: block;
    margin: 0 0 24px;
    padding: 0;
    //width: 868px;
    border-collapse: collapse;
    border-spacing: 0 0;

    .Table__tr {
        margin: 0;
        padding: 0;

        .Table__th {
            position: relative;
            margin: 0;
            padding: 16px 0 0;
            width: 864px;
            //height: 70px;
            color: #9DA7AE;
            background-color: white;
            border: 2px solid #E6EAED;
            cursor: default;

            .Th__title {
                float: left;
                display: inline-block;
                padding: 0 24px 17px;
                //margin-right: 24px;
                //width: 518px;
                font-family: Roboto, sans-serif;
                font-weight: 400;
                font-size: 16px;
                text-align: left;
            }

            .Th__period {
                display: inline-block;
                padding: 0 24px 17px;
                //margin-right: 48px;
                width: 148px;
                font-family: Roboto, sans-serif;
                font-weight: 400;
                font-size: 16px;
                text-align: right;
            }

            .Th__count {
                float: right;
                display: inline-block;
                padding: 0 24px 17px;
                width: 128px;
                font-family: Roboto, sans-serif;
                font-weight: 400;
                font-size: 16px;
                text-align: right;
            }
        }

        .Table__td {
            position: relative;
            margin: 0;
            //padding: 19px 0 14px;
            padding: 13px 0 12px;
            width: 864px;
            background-color: white;
            border: 2px solid #E6EAED;

            .Program__name {
                //width: 400px;
                vertical-align: top;
                display: inline-block;
                margin-left: 24px;
                font-family: Roboto, sans-serif;
                font-weight: 400;
                font-size: 16px;
                padding-top: 5px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                word-wrap: break-word;
            }

            .Program__count {
                vertical-align: top;
                float: right;
                text-align: right;
                display: inline-block;
                padding: 0 24px 0 0;
                font-family: Roboto, sans-serif;
                font-weight: 900;
                font-size: 24px;
                color: #2A3E4C;
                text-transform: uppercase;
            }

            .Program__count_edit {
                position: absolute;
                top: 18px;
                right: 249px;
                font-family: 'Roboto', sans-serif;
                font-size: 16px;
                font-weight: 400;
                color: #0066CC;
                text-transform: none;
                //opacity: 0;
                opacity: 0.7;
                cursor: pointer;
                text-align: right;
            }

            .Program__edit {
                position: absolute;
                top: 18px;
                right: 110px;
                font-family: 'Roboto', sans-serif;
                font-size: 16px;
                font-weight: 400;
                color: #0066CC;
                text-transform: none;
                //opacity: 0;
                opacity: 0.7;
                cursor: pointer;
                text-align: right;
            }

            .Program__delete {
                position: absolute;
                top: 18px;
                right: 24px;
                font-family: 'Roboto', sans-serif;
                font-size: 16px;
                font-weight: 400;
                color: #F44336;
                text-transform: none;
                opacity: 0;
                //opacity: 0.7;
                cursor: pointer;
                text-align: right;
            }

            .Link {
                color: #61717B;

                &:hover {
                    color: #2A3E4C;
                }
            }

            &:hover {
                color: #2A3E4C;
                background-color: white;

                .Link {
                    color: #2A3E4C;
                }

                .Program__count {
                    opacity: 0;
                }

                .Program__count_edit {
                    opacity: 0.7;
                    transition: ease 0.3s;

                    &:hover {
                        opacity: 1;
                    }
                }

                .Program__edit {
                    opacity: 0.7;
                    transition: ease 0.3s;

                    &:hover {
                        opacity: 1;
                    }
                }

                .Program__delete {
                    opacity: 0.7;
                    transition: ease 0.3s;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

    .Files__table {
        display: block;
        margin: 0 0 42px;
        padding: 0;
        //width: 868px;
        border-collapse: collapse;
        border-spacing: 0 0;

        .Table__tr {
            margin: 0;
            padding: 0;

            .Table__td {
                position: relative;
                margin: 0;
                padding: 19px 0 14px;
                width: 864px;
                background-color: white;
                border: 2px solid #E6EAED;

                .File__format {
                    vertical-align: top;
                    width: 42px;
                    text-align: center;
                    display: inline-block;
                    margin-left: 16px;
                    font-family: Roboto, sans-serif;
                    font-weight: 900;
                    font-size: 16px;

                    &_pdf {
                        color: #F44336;
                    }

                    &_doc {
                        color: #3F51B5;
                    }

                    &_xls {
                        color: #4CAF50;
                    }

                    &_ppt {
                        color: #FF5722;
                    }

                    &_png {
                        color: #9DA7AE;
                    }

                    &_jpg {
                        color: #9DA7AE;
                    }
                }

                .File__name {
                    vertical-align: top;
                    display: inline-block;
                    margin-left: 16px;
                    font-family: Roboto, sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                }

                .File__size {
                    vertical-align: top;
                    float: right;
                    text-align: right;
                    display: inline-block;
                    padding: 0 24px 0 0;
                    font-family: Roboto, sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    color: #9DA7AE;
                    text-transform: uppercase;
                }

                .File__download {
                    position: absolute;
                    top: 19px;
                    right: 24px;
                    font-family: 'Roboto', sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    color: #0066CC;
                    text-transform: none;
                    opacity: 0;
                    cursor: pointer;
                    text-align: right;
                }

                .File__delete {
                    position: absolute;
                    top: 19px;
                    right: 24px;
                    font-family: 'Roboto', sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    color: #F44336;
                    text-transform: none;
                    opacity: 0;
                    cursor: pointer;
                    text-align: right;
                }

                .Link {
                    color: #61717B;

                    &:hover {
                        color: #2A3E4C;
                    }
                }

                &:hover {
                    color: #2A3E4C;
                    background-color: white;

                    .Link {
                        color: #2A3E4C;
                    }

                    .File__size {
                        opacity: 0;
                    }

                    .File__download {
                        opacity: 0.7;
                        transition: ease 0.3s;

                        &:hover {
                            opacity: 1;
                        }
                    }

                    .File__delete {
                        opacity: 0.7;
                        transition: ease 0.3s;

                        &:hover {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
