.Notification {
    left: 0;
    right: 0;
    bottom: 0;
    padding-bottom: 16px;
    pointer-events: none;
    position: fixed;
    width: 100%;
    z-index: 9999;

    .Messages__block {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        padding: 0 8px;
        pointer-events: none;
        text-align: right;

        .Messages__short {
            pointer-events: auto;
            display: inline-block;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 8px;
            padding: 11px 10px 10px;
            border: 2px solid;
            -webkit-border-radius: 9px;
            -moz-border-radius: 9px;
            border-radius: 6px;
            cursor: pointer;
            letter-spacing: .3px;
            transition: .37s;

            &.red {
                color: #fff;
                background-color: #F44336;
                border-color: #F44336;
            }

            &.blue {
                color: #fff;
                background-color: #0066CC;
                border-color: #0066CC;
            }

            &.green {
                color: white;
                background-color: #4CAF50;
                border-color: #4CAF50;
            }

            &.black {
                color: white;
                background-color: #202020;
                border-color: #202020;
            }

        }
    }
}
